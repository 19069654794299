// Colours
$main-text-colour: #800000;
$main-background-colour: #ffff99;
$main-link-colour: #008000;
$main-nav-background-colour: #000080;
$main-family-nav-background-colour: #89895b;

// Typefaces
$site-typeface: Garamond, 'Times New Roman', serif;

// Text Sizes
$header-text-size: 1.84em;
$h1-text-size: 1.50em;
$h2-text-size: 1.17em;
$h3-text-size: 1.00em;
$caption-text-size: 0.80em;

// Two-Column Table Mixin
@mixin two-column-table($column-two-width) {
    td:first-child {
        width: $column-two-width;
    }
}

// Indentation Mixin
@mixin indent($indent-width) {
    margin-left: $indent-width;
}

// First Line Indentation Mixin
@mixin indent-first-line($indent-width) {
    text-indent: $indent-width;
}

body {
    background-color: $main-background-colour;
    color: $main-text-colour;
    font-family: $site-typeface;
    margin: 0;
    width: 100%;
}

h1 {
    font-size: $h1-text-size;
    text-align: center;
}

h2 {
    font-size: $h2-text-size;
    text-align: center;
}

h3 {
    font-size: $h3-text-size;
}

a {
    color: $main-link-colour;
}

.captioned {
    font-size: $caption-text-size;
    font-weight: bold;
}

.nav {
    a {
        color: $main-background-colour;
        font-weight: bold;
        text-decoration: none;
    }

    ul {
        margin: 0;

        li {
            list-style-type: none;
        }
    }
}

#header {
    background-color: $main-link-colour;
    color: $main-background-colour;

    h1 {
        font-size: $header-text-size;
        margin: 0;
        padding: 0.5em;
    }
}

#navSite {
    background-color: $main-nav-background-colour;
    color: $main-background-colour;

    ul {
        padding: 0;

        li {
            display: inline-block;
            padding: 0.5em;

            a {
                color: $main-background-colour;
            }
        }
    }
}

#contentWrapper {
    display: flex;
    width: 100%;

    #navFamily {
        background-color: $main-text-colour;
        flex-basis: 200px;
        float: left;
        text-align: center;

        ul {
            padding: 10px;

            li {
                a {
                    background-color: $main-family-nav-background-colour;
                    display: block;
                    margin: 2px;
                    padding: 10px 0 10px 0;
                    width: 175px;
                }
            }
        }
    }

    #mainContent {
        flex-basis: auto;
        padding: 2px 20px 0 20px;
        width: 100%;

        .centre-text {
            text-align: center;
        }

        .right-text {
            text-align: right;
        }

        .indent {
            @include indent(3em);
        }

        .indent-first-line {
            @include indent-first-line(3em);
        }

        .full-pdf-viewer {
            width: 100%;
        }

        .books-collection-table {
            td {
                vertical-align: top;
                padding-bottom: 1em;

                img {
                    height: 150px;
                }
            }
        }

        #familyHistory {
            text-align: justify;

            .double-indent-list {
                margin-left: 40px;
            }
        }

        .wheel-pedigree-table {
            @include two-column-table(75px);
        }

        .letters-wolff-g-table {
            @include two-column-table(150px);
        }

        .letters-wolff-g-postcards-table {
            width: 100%;
            td {
                text-align: center;
            }
        }

        .diaries-goodman-wb-table {
            td:first-child {
                width: 250px;
            }

            td:nth-child(2), td:nth-child(3) {
                width: 25px;
            }
        }

        .diaries-whitlock-hc-table {
            @include two-column-table(400px);
            td {
                vertical-align: bottom;
            }
        }
    }
}